import {computed, makeAutoObservable, runInAction} from 'mobx'
import ApiClient from "../ApiClient";
import {AxiosResponse} from "axios";

export interface BaseApiResponse<T> {
    success: boolean;
    errorType?: string;
    body: T;
}

export type CheckMessageResponseType = 'OK' | 'NOT_OK' | 'ASK_AGAIN';

export type UserType = {
    userId: number;
    venueId: string;

    emailAddress: string;
};

export type Venue = {
    venueId: number;
    displayName: string;
    nameKeyStr: string;

    commCenAppIconUrl: string;
    userCheckWebsiteText: string;

    stateLocation: string;
    
    // iconUrl: string;
    // backgroundUrl: string;

    // venueFeatures: VenueFeatures;
    // userVenuePermissions: UserPermissions
};

// export type VenueFeatures = {
//     queryStringAttractors: boolean
//     draggableAttractors: boolean
//     mobileAttractorsByTier: boolean
//     mobileAboutItemAttachments: boolean
// };
//
// export type UserPermissions = {
//     isAdmin: boolean;
//     hasAnalyticsAccess: boolean;
// };

export default class UserState {
    venue?: Venue = undefined;
    loadRequestComplete = false;
    
    constructor() {
        makeAutoObservable(this);
    }
    
    @computed get getVenueId() {
        return this.venue?.venueId;
    }
    
    loadVenueData = (venueTag: string) => {
        ApiClient.getVenueSummary(venueTag)
            .then((axRes) => {
                const response = axRes.data;
                
                runInAction(() => {
                    this.loadRequestComplete = true;
                    this.venue = response.venueSummary;
                });
            })
            .catch(error => {
                this.loadRequestComplete = true;
            });
    }
};

export const userState = new UserState();