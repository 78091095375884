import React, {ReactNode, useContext, Fragment} from 'react';
import { Container } from 'reactstrap';
import { VenueBrandHeaderBar } from './VenueBrandHeaderBar';
import {AppStore} from "../../index";
import {observer} from "mobx-react";

const Layout = (props: { children: ReactNode }) => {
    const appState = useContext(AppStore);
    
    return (
        <Fragment>
            {appState.currentPageRoute === 'USER_CHECK_PAGE' ? (
                <div style={{ minHeight: '100vh', background: 'linear-gradient(90deg, #F05832, #E22526)' }}>
                    {props.children}
                </div>
            ) : (
                <Fragment>
                    <VenueBrandHeaderBar />
                    
                    <Container>
                        {props.children}
                    </Container>
                </Fragment>
            )}
        </Fragment>
    );
}

export default observer(Layout);