import React, { Component } from 'react';
import {Route, Switch} from 'react-router';
import {Home} from "./components/pages/Home";
import {PresentationForm} from "./components/pages/PresentationForm";
import UserCheckPage from "./components/pages/UserCheckPage";
import Layout from "./components/layout/Layout";
import SmsOptOutPage from "./components/pages/SmsOptOutPage";

export const App = () => {
    return (
        <Layout>
            <Switch>
                <Route exact path='/presentation' component={PresentationForm} />
                <Route exact path='/qr/:venueTag' component={Home} />
                <Route exact path='/u/:checkToken' component={UserCheckPage} />
                <Route exact path='/:venueTag' component={Home} />
                <Route exact path='/opt/:optOutHash' component={SmsOptOutPage} />
            </Switch>
        </Layout>
    );
}
