/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, {useContext, useState, Fragment} from 'react';
import { observer } from 'mobx-react';
import {UserStore} from "../../index";
import {
    Card,
    CardBody,
    CardText,
    Container, 
    DropdownItem, 
    DropdownMenu, 
    DropdownToggle, 
    UncontrolledDropdown
} from "reactstrap";
import ApiClient from "../../ApiClient";
import {Colors} from "../../Theme";
import {CheckCircleFill} from "react-bootstrap-icons";
import PhoneInput, {isValidPhoneNumber, Value} from "react-phone-number-input";

const validStates = [
    { name: 'New South Wales', key: 'NSW' },
    { name: 'Victoria', key: 'VIC' },
    { name: 'Queensland', key: 'QLD' },
    { name: 'South Australia', key: 'SA' },
    { name: 'Western Australia', key: 'WA' },
    { name: 'Tasmania', key: 'TAS' },
    // { name: 'Northern Territory', key: 'NT' },
    // { name: 'Australian Capital Territory', key: 'ACT' },
] as const
type StateTypes = typeof validStates[number]['key'] | undefined

// const validStates = ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'NT', 'ACT'] as const
// export type StateTypes = (typeof validStates)[number] | undefined

// export type StateTypes = undefined | 'NSW' | 'VIC' | 'QLD' | 'WA' | 'SA' | 'TAS';

export type PresentationFormType = {
    fullName: string;
    phoneNumber: string;
    stateLocation: StateTypes;
    clubName: string;
};

export const PresentationForm = observer(() => {
    const venueStore = useContext(UserStore);
    
    const presentationSignUpInitialState: PresentationFormType = {
        fullName: "",
        phoneNumber: "",
        stateLocation: undefined,
        clubName: ""
    };
    
    const [presentationSignUp, setPresentationSignUp] = useState(presentationSignUpInitialState);
    const [cancelRequest, setCancelRequest] = useState(false);
    const [submittedRequest, setSubmittedRequest] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [stateLocationError, setStateLocationError] = useState(false);
    const [clubNameError, setClubNameError] = useState(false);

    const submitHelp = () => {
        let hasError = false;

        if (presentationSignUp.fullName.length == 0) {
            hasError = true;
            setNameError(true);
        }

        if (!isValidPhoneNumber(presentationSignUp.phoneNumber)) {
            hasError = true;
            setMobileError(true);
        }
        
        if (presentationSignUp.stateLocation == undefined) {
            hasError = true;
            setStateLocationError(true);
        }
        
        if (presentationSignUp.clubName.length == 0) {
            hasError = true;
            setClubNameError(true);
        }
        
        if (hasError)
            return;
        
        ApiClient.createPresentationSignup(
            presentationSignUp.fullName,
            presentationSignUp.phoneNumber,
            presentationSignUp.stateLocation!!.toString(),
            presentationSignUp.clubName
        )
            .then(response => {
                setSubmittedRequest(true);
            })
            .catch((error) => {
                
            });
    };
    
    const submittedView = () => {
        return <div style={{ padding: 32, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
            <CheckCircleFill size={44} color={Colors.GreenMatte} style={{ margin: 'auto' }} />
            
            <div style={{ fontSize: 22, fontWeight: "bold", marginTop: 10 }}>
                Success!
            </div>

            {/*<div style={{ fontSize: 18, marginTop: 8 }}>*/}
            {/*    We're received your request... keep your mobile on hand.*/}
            {/*</div>*/}
            
            <div style={{ fontSize: 18, marginTop: 8 }}>
                Someone will be in touch with you shortly.
            </div>
        </div>
    }

    const cancelledView = () => {
        return <div style={{ padding: 32, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
            <div style={{ fontSize: 22, fontWeight: "bold" }}>
                We've cancelled your Help Request
            </div>

            <div style={{ fontSize: 18, marginTop: 8 }}>
                If you ever need help or assistance, please check back here.
            </div>
        </div>
    }
    
    const normalView = () => {
        return <Fragment>
            <div style={{ marginTop: 20, marginBottom: 20, fontSize: 18, textAlign: 'center', fontWeight: 'bold', maxWidth: 443 }}>
                Leave your details and one of the <b>OK2PLAY</b> team will call you to meet your needs
            </div>
            
            <div className="flex-cen-stack">
                {/*<CardText style={{ fontSize: '1.5rem', marginTop: 12 }}>*/}
                {/*    Contact Details*/}
                {/*</CardText>*/}

                <input
                    css={css({ width: 280, padding: 6, marginBottom: 8, borderRadius: 8 })}
                    placeholder={'Enter Full Name'}
                    type={'text'}
                    value={presentationSignUp.fullName}
                    onChange={({ target: { value } }) => {
                        setNameError(false);
                        
                        setPresentationSignUp(prevState => ({
                            ...prevState,
                            fullName: value
                        }))
                    }}
                />

                {nameError && (
                    <div style={{ color: 'red', fontSize: 16, marginTop: -8, marginBottom: 8 }}>
                        Please enter your full name
                    </div>
                )}

                <div
                    css={css({ /* width: 360, padding: '6px 0',*/ backgroundColor: 'white', marginBottom: 8 })}
                >
                    <PhoneInput
                        defaultCountry={"AU"}
                        country={"AU"}
                        value={presentationSignUp.phoneNumber}
                        onChange={(value: Value) => {
                            setMobileError(false);

                            setPresentationSignUp(prevState => ({
                                ...prevState,
                                phoneNumber: value
                            }))
                        }}
                        placeholder={"Enter Mobile Number"}
                        css={{
                            '& input' : {
                                padding: 6,
                                borderRadius: 8,
                                width: 280,
                                marginRight: '2.5rem'
                            }
                        }}
                    />
                </div>
                {/*<input*/}
                {/*    css={css({ width: 360, padding: 6, marginBottom: 8, borderRadius: 8 })}*/}
                {/*    placeholder={'Enter Mobile Number'}*/}
                {/*    type={'text'}*/}
                {/*    value={presentationSignUp.phoneNumber}*/}
                {/*    onChange={({ target: { value } }) => {*/}
                {/*        setMobileError(false);*/}
                {/*        setHelpRequest(prevState => ({*/}
                {/*            ...prevState,*/}
                {/*            phoneNumber: value*/}
                {/*        }))*/}
                {/*    }}*/}
                {/*/>*/}
                
                {mobileError && (
                    <div style={{ color: 'red', fontSize: 16, marginTop: -8, marginBottom: 8 }}>
                        Invalid mobile number, please try again
                    </div>
                )}
                
                {/*<input*/}
                {/*    css={css({ width: 360, padding: 6, marginBottom: 8, borderRadius: 8 })}*/}
                {/*    placeholder={'Enter State Location'}*/}
                {/*    type={'text'}*/}
                {/*    value={presentationSignUp.stateLocation}*/}
                {/*    onChange={({ target: { value } }) => {*/}
                {/*        if (isValidState(value)) {*/}
                {/*            setStateLocationError(false);*/}
                {/*            setPresentationSignUp(prevState => ({*/}
                {/*                ...prevState,*/}
                {/*                stateLocation: value*/}
                {/*            }))*/}
                {/*        } else {*/}
                {/*            setStateLocationError(true);*/}
                {/*        }*/}
                {/*    }}*/}
                {/*/>*/}
                
                {/*{stateLocationError && (*/}
                {/*    <div style={{ color: 'red', fontSize: 16, marginTop: -8, marginBottom: 8 }}>*/}
                {/*        Invalid State Entry*/}
                {/*    </div>*/}
                {/*)}*/}
                
                <input
                    css={css({ width: 280, padding: 6, marginBottom: 8, borderRadius: 8 })}
                    placeholder={'Enter Club Name'}
                    type={'text'}
                    value={presentationSignUp.clubName}
                    onChange={({ target: { value } }) => {
                        setClubNameError(false);
                        setPresentationSignUp(prevState => ({
                            ...prevState,
                            clubName: value
                        }))
                    }}
                />

                {clubNameError && (
                    <div style={{ color: 'red', fontSize: 16, marginTop: -8, marginBottom: 8 }}>
                        You must enter your Club's Name
                    </div>
                )}

                <UncontrolledDropdown css={{marginBottom: 8}}>
                    <DropdownToggle caret>
                        {
                            presentationSignUp.stateLocation ?
                                validStates.find(s => s.key === presentationSignUp.stateLocation)!.name
                                : "Select Club State"
                        }
                    </DropdownToggle>
                    <DropdownMenu>
                        { validStates.map((state) => (
                            <DropdownItem key={state.key} onClick={() => {
                                setStateLocationError(false);
                                setPresentationSignUp(prevState => ({
                                    ...prevState,
                                    stateLocation: state.key
                                }))
                            }}>
                                {state.name}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </UncontrolledDropdown>

                {stateLocationError && (
                    <div style={{ color: 'red', fontSize: 16, marginTop: -8, marginBottom: 8 }}>
                        Please select state
                    </div>
                )}
            </div>
            
            <button
                className="btn btn-primary"
                type="submit"
                onClick={submitHelp}
                css={css({
                    marginTop: 12,
                    marginBottom: 6,
                    backgroundColor: '#353637',
                    color: '#EB462C',
                    fontSize: 16,
                    fontWeight: "bold"
                })}
            >
                Submit
            </button>

            {/*<button*/}
            {/*    className="btn btn-primary"*/}
            {/*    type="submit"*/}
            {/*    onClick={() => setCancelRequest(true)}*/}
            {/*    css={css({*/}
            {/*        marginTop: 6,*/}
            {/*        marginBottom: 12,*/}
            {/*        backgroundColor: '#353637',*/}
            {/*        color: '#EB462C',*/}
            {/*        fontSize: 16,*/}
            {/*        fontWeight: "bold"*/}
            {/*    })}*/}
            {/*>*/}
            {/*    Cancel*/}
            {/*</button>*/}
        </Fragment>
    };

    return (
        <Container style={{ marginTop: 64, marginBottom: 24, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
            <img style={{ width: '100%', maxWidth: 700, margin: 'auto', marginBottom: 44 }}
                 src={"https://ok2p-test-bucket.s3.ap-southeast-2.amazonaws.com/ok2play_logo_dark.png"} />

            <div style={{ /*margin: 8,*/ fontSize: 20, textAlign: 'center', maxWidth: 660 }}>
                Thank you for wanting to know more about OK2PLAY the most advanced PLAYER PROTECTION solution available.
            </div>
            
            <Card style={{ marginTop: 18 }}>
                <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                    {cancelRequest ? (
                        cancelledView()
                    ) : (
                        <Fragment>
                            {submittedRequest ? (
                                submittedView()
                            ) : (
                                normalView()
                            )}
                        </Fragment>
                    )}
                </CardBody>
            </Card>
        </Container>
    );
});
