import { makeAutoObservable} from 'mobx'

export type AppPageRoute = 'USER_CHECK_PAGE' | 'HOME' | 'QR' | 'SMS_OPT_OUT_PAGE';

export default class AppState {
    currentPageRoute?: AppPageRoute = undefined;
    
    constructor() {
        makeAutoObservable(this);
    }
};

export const appState = new AppState();