/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, {useContext, useEffect, useState, Fragment} from 'react';
import { observer } from 'mobx-react';
import {AppStore, UserStore} from "../../index";
import {useParams} from "react-router";
import {
    Card,
    CardBody, Col,
    Container, FormGroup, Input, Label
} from "reactstrap";
import ApiClient from "../../ApiClient";
import {GoldBallLoader} from "../ui/Loading";
import {CheckMessageResponseType, Venue} from "../../state/UserState";
import {Simulate} from "react-dom/test-utils";
import {Columns, PageFooter, Rows} from "../UiKit";

const buttonCss = css({
    objectFit: 'contain',
    height: 80,
    margin: '12px 8px',
    '&:hover': {
        cursor: 'pointer'
    }
});

const SmsOptOutPage = () => {
    const venueStore = useContext(UserStore);
    const appState = useContext(AppStore);
    
    let { optOutHash } = useParams();
    
    const [yesOptOut, setYesOptOut] = useState(false);
    const [loadRequestComplete, setLoadRequestComplete] = useState(false);
    const [venueSummary, setVenueSummary] = useState<Venue | undefined>(undefined);
    const [responseSuccess, setResponseSuccess] = useState(false);
    
    const errorMessage = (children: JSX.Element, showTick: boolean = false) => {
        return (
            <Columns css={{
                fontSize: 26,
                textAlign: 'center',
                padding: 16,
                width: '75vw',
                lineHeight: '34px',
                fontFamily: '"Montserrat", sans-serif',
                fontWeight: 700
            }}>
                {children}
                
                {showTick && (
                    <img css={{
                        marginTop: 16,
                        width: 130
                    }}
                         src={"green_tick.png"}
                    />                    
                )}
            </Columns>
        )
    }
    
    const successView = () => {
        return (
            <Columns css={{
                fontSize: 26,
                textAlign: 'center',
                padding: 16,
                width: '75vw',
                lineHeight: '34px',
                fontFamily: '"Montserrat", sans-serif',
                fontWeight: 700
            }}>
                <span>YOU HAVE OPTED OUT OF FUTURE SMS</span>
                
                <img css={{
                    marginTop: 16,
                    width: 130
                }}
                    src={"green_tick.png"}
                />
            </Columns>
        )
    }
    
    const loadingView = () => {
        return (
            <Container style={{ marginTop: 24, marginBottom: 24 }}>
                <div style={{ fontSize: 26, textAlign: 'center', marginBottom: 16 }}>
                    Loading...
                </div>
                <GoldBallLoader />
            </Container>
        )
    }
    
    useEffect(() => {
        appState.currentPageRoute = 'SMS_OPT_OUT_PAGE';
        
        ApiClient.loadSmsOptOut(optOutHash)
            .then((axRes) => {
                setLoadRequestComplete(true);
                const response = axRes.data;
                
                venueStore.venue = response.body.venueSummary;
                venueStore.loadRequestComplete = true;
                
                // if (response.errorType === 'CONSUMED') setAlreadyAnswered(true);
                if (response.success) setVenueSummary(response.body.venueSummary);
            })
            .catch((e) => {})
                setLoadRequestComplete(true);
    }, [])
        
    const doOptOut = () => {
        ApiClient.setSmsOptOutResponse(optOutHash)
            .then((response) => {
                const success = response.data.success;
                
                if (!success) return;
                
                setResponseSuccess(true);
                
            })
            .catch(() => {})
    };
    
    const normalView = () => {
        return <Fragment>
            <div style={{
                marginTop: 90,
                marginBottom: 45,
                fontSize: 28,
                fontFamily: '"Montserrat", sans-serif',
                fontWeight: 700,
                textAlign: 'center'
            }}>
                Are you sure you wish to opt out of all future SMS?
            </div>
    
            {yesOptOut ? (
                <div style={{ marginTop: 40, marginBottom: 80, maxWidth: '80vw' }}>
                    <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={doOptOut}
                        css={css({
                            marginTop: 12,
                            marginBottom: 6,
                            backgroundColor: 'red',
                            color: 'white',
                            fontSize: 16,
                            fontWeight: "bold"
                        })}
                    >
                        Confirm
                    </button>
                </div>
            ) : (
                <FormGroup
                    check
                    style={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: 'center', marginBottom: 80, marginTop: 40 }}
                >
                    <Input
                        style={{ width: 54 }}
                        name="radio1"
                        type="radio"
                        onClick={() => {
                            setYesOptOut(true);
                        }}
                    />
                    {' '}
                    <Label
                        style={{ marginLeft: 36 }}
                        check
                    >
                        Yes I am sure
                    </Label>
                </FormGroup>
            )}
        </Fragment>
    };
    
    const getViewSwitch = () => {
        if (!loadRequestComplete) return <Card style={{ marginTop: 40, marginBottom: 40, maxWidth: '80vw' }}>
            <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                {loadingView()}
            </CardBody>
        </Card>
        
        if (!venueSummary) return <Card style={{ marginTop: 40, marginBottom: 40, maxWidth: '80vw' }}>
            <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                {errorMessage(
                    <Fragment>
                        <span>SORRY!</span>
                        <span>WE CANT FIND</span>
                        <span>THE PAGE YOU'RE LOOKING FOR</span>
                    </Fragment>
                , false)}
            </CardBody>
        </Card>
        
        if (responseSuccess) return <Card style={{ marginTop: 40, marginBottom: 40, maxWidth: '80vw' }}>
            <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                {successView()}
            </CardBody>
        </Card>
        
        return normalView();
    }
    
    return (
        <div
            css={css({
                minHeight: '100vh',
                width: '100%',
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingTop: 60
        })}>
            <img
                css={css({
                    objectFit: 'contain',
                    maxWidth: '75vw'
            })}
                src={"https://ok2p-test-bucket.s3.ap-southeast-2.amazonaws.com/ok2play_logo_dark.png"}
            />

            {getViewSwitch()}
            
            <div css={css({
                background: 'black',
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 40
            })}>
                <div
                    css={css({
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'white',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 65,
                        marginBottom: 75,
                        fontSize: 30,
                        fontFamily: '"Montserrat", sans-serif',
                        fontWeight: 900,
                        lineHeight: '32px'
                })}>
                    <span>STAY IN CONTROL</span>
                    <span>WITH OK2PLAY?</span>
                </div>
                
                <PageFooter stateLocation={venueSummary?.stateLocation} />
            </div>
        </div>
    );
};

export default observer(SmsOptOutPage);
