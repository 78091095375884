import axios, {AxiosResponse, CancelToken, CancelTokenSource} from 'axios';
import UserStore from "./store/UserStore";
import Axios from "axios";
import UserState, {BaseApiResponse, CheckMessageResponseType, Venue} from "./state/UserState";
import {LoadUserQuestionResponse} from "./rest/LoadUserQuestionResponse";

class ApiClient {
    // userStore = UserStore;
    userState!: UserState;
    apiBaseUrl!: string;
    
    init(apiBaseUrl: string, userState: UserState) {
        this.userState = userState;
        this.apiBaseUrl = apiBaseUrl;
        
        // Catch all 401/403 and force a user logout
        Axios.interceptors.response.use(response => {
            // Do something with response data
            return response;
        }, error => {
            if (401 === error.response.status || 403 === error.response.status) {
                console.log('FOUND 401 STATUS')
                // userState.logout();
            } else {
                return Promise.reject(error);
            }
        });
        
        Axios.defaults.withCredentials = true;
    }

    getVenueSummary(venueTag: string) {
        const url = `${this.apiBaseUrl}/v1/qrweb/public/venue/${venueTag}/summary`;

        return Axios.get<LoadUserQuestionResponse>(url);
    }
    
    loadUserQuestion(checkToken: string) {
        const url = `${this.apiBaseUrl}/v1/qrweb/public/user-check-token`;
        
        return Axios.post<BaseApiResponse<LoadUserQuestionResponse>>(url, { checkToken });
    }    
    
    loadSmsOptOut(smsOptOutHash: string) {
        const url = `${this.apiBaseUrl}/v1/qrweb/public/sms-opt-out`;
        
        return Axios.post<BaseApiResponse<LoadUserQuestionResponse>>(url, { smsOptOutHash });
    }

    setSmsOptOutResponse(smsOptOutHash: string) {
        const url = `${this.apiBaseUrl}/v1/qrweb/public/sms-opt-out/response`;

        return Axios.post<BaseApiResponse<void>>(url, { smsOptOutHash });
    }
    
    setUserQuestionResponse(checkToken: string, response: CheckMessageResponseType) {
        const url = `${this.apiBaseUrl}/v1/qrweb/public/user-check-token/response`;
        
        return Axios.post<BaseApiResponse<void>>(url, { checkToken, response });
    }
    
    createHelpRequest(venueId: number, firstName: string, lastName: string, mobileNumber: string, emailAddress: string, helpKind: string) {
        const url = `${this.apiBaseUrl}/v1/qrweb/public/help-request/web`;

        return Axios.post(url, {
            venueId,
            firstName,
            lastName,
            mobileNumber,
            emailAddress,
            helpKind
        });
    }    
    
    createPresentationSignup(fullname: string, mobileNumber: string, stateLocation: string, clubName: string) {
        const url = `${this.apiBaseUrl}/v1/qrweb/public/presentation-signup`;

        return Axios.post(url, {
            fullname,
            mobileNumber,
            stateLocation,
            clubName
        });
    }
}

export default new ApiClient();