import React, {Component, useContext} from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './VenueBrandHeaderBar.scss';
import {observer} from "mobx-react";
import {UserStore} from "../../index";
import {jsx} from "@emotion/core";

export const VenueBrandHeaderBar = observer(() => {
    const venueStore = useContext(UserStore);
  
    // constructor() {
    //   super();
    //
    //   this.toggleNavbar = this.toggleNavbar.bind(this);
    //   this.state = {
    //     collapsed: true
    //   };
    // }
    
    const toggleNavbar = () => {
    //   this.setState({
    //     collapsed: !this.state.collapsed
    //   });
    };
    
    // isOpen={!this.state.collapsed}
        
    return (
        <header>
            <Navbar
                className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" 
                light
                style={{ backgroundColor: 'lightgray' }}
            >
                <Container style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
                    
                    <img
                        style={{ width: 86 }}
                        src={venueStore.venue?.commCenAppIconUrl} />

                    <div style={{ fontSize: 20 }}>
                        {venueStore.venue?.displayName}
                    </div>

                    
                    {/*<NavbarBrand tag={Link} to="/">Ok2Play</NavbarBrand>*/}
          
                    {/*<NavbarToggler onClick={toggleNavbar} className="mr-2" />*/}
          
                    {/*<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={false} navbar>*/}
                    {/*    <ul className="navbar-nav flex-grow">*/}
                    {/*        <NavItem>*/}
                    {/*            <NavLink tag={Link} className="text-dark" to="/">Venue Name</NavLink>*/}
                    {/*        </NavItem>*/}
                    {/*    </ul>*/}
                    {/*</Collapse>*/}
          
              </Container>
          </Navbar>
      </header>
    );
});
