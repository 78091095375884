/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, {useContext, useEffect, useState, Fragment} from 'react';
import { observer } from 'mobx-react';
import {AppStore, UserStore} from "../../index";
import {useParams} from "react-router";
import {
    Card,
    CardBody, Col,
    Container
} from "reactstrap";
import ApiClient from "../../ApiClient";
import {GoldBallLoader} from "../ui/Loading";
import {CheckMessageResponseType, Venue} from "../../state/UserState";
import {Simulate} from "react-dom/test-utils";
import {Columns, PageFooter, Rows} from "../UiKit";

const buttonCss = css({
    objectFit: 'contain',
    height: 80,
    margin: '12px 8px',
    '&:hover': {
        cursor: 'pointer'
    }
});

const UserCheckPage = () => {
    const venueStore = useContext(UserStore);
    const appState = useContext(AppStore);
    
    let { checkToken } = useParams();
    
    const [alreadyAnswered, setAlreadyAnswered] = useState(false);
    const [loadRequestComplete, setLoadRequestComplete] = useState(false);
    const [venueSummary, setVenueSummary] = useState<Venue | undefined>(undefined);
    const [responseSuccess, setResponseSuccess] = useState(false);
    const [responseSaved, setResponseSaved] = useState<CheckMessageResponseType | undefined>(undefined);
    
    const errorMessage = (children: JSX.Element, showTick: boolean = false) => {
        return (
            <Columns css={{
                fontSize: 26,
                textAlign: 'center',
                padding: 16,
                width: '75vw',
                lineHeight: '34px',
                fontFamily: '"Montserrat", sans-serif',
                fontWeight: 700
            }}>
                {children}
                
                {showTick && (
                    <img css={{
                        marginTop: 16,
                        width: 130
                    }}
                         src={"green_tick.png"}
                    />                    
                )}
            </Columns>
        )
    }
    
    const successView = () => {
        if (responseSaved === 'OK') {
            return (
                <Columns css={{
                    fontSize: 26,
                    textAlign: 'center',
                    padding: 16,
                    width: '75vw',
                    lineHeight: '34px',
                    fontFamily: '"Montserrat", sans-serif',
                    fontWeight: 700
                }}>
                    <span>THANK YOU,</span>
                    <span>YOUR RESPONSE</span>
                    <span>HAS BEEN HEARD</span>
                    
                    <img css={{
                        marginTop: 16,
                        width: 130
                    }}
                        src={"green_tick.png"}
                    />
                </Columns>
            )
        } else if (responseSaved === 'NOT_OK') {
            return (
                <Columns css={{
                    fontSize: 26,
                    textAlign: 'center',
                    padding: 16,
                    width: '75vw',
                    lineHeight: '34px',
                    fontFamily: '"Montserrat", sans-serif',
                    fontWeight: 700
                }}>
                    <span>THANK YOU,</span>
                    <span>YOUR RESPONSE</span>
                    <span>HAS BEEN HEARD</span>
                    
                    <img css={{
                        marginTop: 16,
                        width: 130
                    }}
                         src={"green_tick.png"}
                    />
                </Columns>
            )
        }
    }
    
    const loadingView = () => {
        return (
            <Container style={{ marginTop: 24, marginBottom: 24 }}>
                <div style={{ fontSize: 26, textAlign: 'center', marginBottom: 16 }}>
                    Loading...
                </div>
                <GoldBallLoader />
            </Container>
        )
    }
    
    useEffect(() => {
        appState.currentPageRoute = 'USER_CHECK_PAGE';
        
        ApiClient.loadUserQuestion(checkToken)
            .then((axRes) => {
                setLoadRequestComplete(true);
                const response = axRes.data;
                
                venueStore.venue = response.body.venueSummary;
                venueStore.loadRequestComplete = true;
                
                if (response.errorType === 'CONSUMED') setAlreadyAnswered(true);
                if (response.success) setVenueSummary(response.body.venueSummary);
            })
            .catch((e) => {})
                setLoadRequestComplete(true);
    }, [])
        
    const setQuestionResponse = (checkMessageResponse: CheckMessageResponseType) => {
        ApiClient.setUserQuestionResponse(checkToken, checkMessageResponse)
            .then((response) => {
                const success = response.data.success;
                
                if (!success) return;
                
                setResponseSuccess(true);
                setResponseSaved(checkMessageResponse);
                
            })
            .catch(() => {})
    };
    
    const normalView = () => {
        return <Columns>
            <Rows>
                <img
                    css={buttonCss}
                    onClick={() => setQuestionResponse('OK')}
                    src={"button_attend.png"}
                />

                <img
                    css={buttonCss}
                    onClick={() => setQuestionResponse('NOT_OK')}
                    src={"button_cant_attend.png"}
                />
            </Rows>
            
            {/*<img*/}
            {/*    css={buttonCss}*/}
            {/*    onClick={() => setQuestionResponse('ASK_AGAIN')}*/}
            {/*    src={"ask_again_button.png"}*/}
            {/*/>*/}
        </Columns>
    };
    
    const getViewSwitch = () => {
        if (!loadRequestComplete) return <Card style={{ marginTop: 40, marginBottom: 40, maxWidth: '80vw' }}>
            <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                {loadingView()}
            </CardBody>
        </Card>

        if (alreadyAnswered) return <Card style={{ marginTop: 40, marginBottom: 40, maxWidth: '80vw' }}>
            <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                {errorMessage(
                    <Fragment>
                        <span>THANK YOU,</span>
                        <span>YOUR RESPONSE</span>
                        <span>HAS ALREADY BEEN HEARD</span>
                    </Fragment>
                , true)}
            </CardBody>
        </Card>
        
        if (!venueSummary) return <Card style={{ marginTop: 40, marginBottom: 40, maxWidth: '80vw' }}>
            <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                {errorMessage(
                    <Fragment>
                        <span>SORRY!</span>
                        <span>WE CANT FIND</span>
                        <span>THE PAGE YOU'RE LOOKING FOR</span>
                    </Fragment>
                , false)}
            </CardBody>
        </Card>
        
        if (responseSuccess) return <Card style={{ marginTop: 40, marginBottom: 40, maxWidth: '80vw' }}>
            <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                {successView()}
            </CardBody>
        </Card>
        
        return normalView();
    }
    
    const getConfigurableContent = (): JSX.Element => {
        let elements = venueStore.venue?.userCheckWebsiteText
            .split('[newline]')
            .map((line) => {
                return <div style={{
                    minHeight: 28,
                    lineHeight: '36px',
                    textAlign: 'center',
                    maxWidth: '77vw'
                }}>
                    {line}
                </div>
            })
        
        return <div style={{
            marginTop: 90,
            marginBottom: 45,
            fontSize: 28,
            fontFamily: '"Montserrat", sans-serif',
            fontWeight: 700
        }}>
            {elements}
        </div>;
    }
    
    return (
        <div
            css={css({
                minHeight: '100vh',
                width: '100%',
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingTop: 60
        })}>
            <img
                css={css({
                    objectFit: 'contain',
                    maxWidth: '75vw'
            })}
                src={"okcheck_logo.png"}
            />

            {getConfigurableContent()}

            <div style={{
                fontSize: 44,
                textAlign: 'center',
                maxWidth: '90vw',
                lineHeight: '56px',
                fontFamily: '"Montserrat", sans-serif',
                fontWeight: 900,
                color: 'white'
            }}>
                ARE YOU OK2PLAY?
            </div>
            
            <div style={{ marginTop: 40, marginBottom: 40, maxWidth: '80vw' }}>
                {getViewSwitch()}
            </div>
            
            <div css={css({
                background: 'black',
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 40
            })}>
                <div
                    css={css({
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'white',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 65,
                        marginBottom: 75,
                        fontSize: 30,
                        fontFamily: '"Montserrat", sans-serif',
                        fontWeight: 900,
                        lineHeight: '32px'
                })}>
                    <span>STAY IN CONTROL</span>
                    <span>WITH OK2PLAY?</span>
                </div>
                
                <PageFooter stateLocation={venueSummary?.stateLocation} />
            </div>
        </div>
    );
};

export default observer(UserCheckPage);
