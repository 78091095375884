/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, {Component, useContext, useEffect, useState, Fragment} from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import {AppStore, UserStore} from "../../index";
import {useHistory, useParams} from "react-router";
import {reaction, when} from "mobx";
import {userState} from "../../state/UserState";
import {
    Card,
    CardBody,
    CardColumns,
    CardHeader,
    CardSubtitle,
    CardText,
    CardTitle,
    Container, Form,
    FormGroup, Input, Label
} from "reactstrap";
import ReactModal from 'react-modal';
import ApiClient from "../../ApiClient";
import {Colors} from "../../Theme";
import {Columns, secondsSinceToTimestamp} from "../UiKit";
import {CheckCircleFill, XCircleFill} from "react-bootstrap-icons";
import {BallLoader, GoldBallLoader} from "../ui/Loading";

let buttonCss = css({
    margin: 'auto',
    display: 'block',
    backgroundColor: Colors.LegoDark,
    borderColor: Colors.LegoDark
});

let borderCss = css({
    border: '1px solid lightgrey',
    padding: 8,
    margin: 4,
    borderRadius: 12
});

let buttonLightCss = css({
    margin: 'auto',
    display: 'block',
    backgroundColor: Colors.LightGrey,
    borderColor: Colors.LightGrey
});

let buttonStyle = {
    margin: 'auto',
    display: 'block',
    backgroundColor: Colors.LegoDark,
    borderColor: Colors.LegoDark,
    marginRight: 12
};

let countCircleCss = css({
    backgroundColor: 'lightgray',
    borderRadius: '50%',
    height: 100,
    width: 100,
    marginBottom: 6
});

let modalStyle = {
    content: {
        top: '20%',
        left: '20%',
        right: '20%',
        bottom: '20%',
        overscrollBehavior: 'contain'
    }
};

export const Home = observer(() => {
    const venueStore = useContext(UserStore);
    const appState = useContext(AppStore);
    const history = useHistory();
    let { venueTag } = useParams();
        
    const helpRequestInitialState = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailAddress: "",
        helpKind: ""
    };
    
    const [helpRequest, setHelpRequest] = useState(helpRequestInitialState);
    const [cancelRequest, setCancelRequest] = useState(false);
    const [submittedRequest, setSubmittedRequest] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [helpKindError, setHelpKindError] = useState(false);
    
    useEffect(() => {
        appState.currentPageRoute = 'HOME';
        venueStore.loadVenueData(venueTag);
    }, [])
    
    if (!venueStore.loadRequestComplete) {
        return (
            <Container style={{ marginTop: 24, marginBottom: 24 }}>
                <div style={{ fontSize: 26, textAlign: 'center', marginBottom: 16 }}>
                    Loading Form...
                </div>
                <GoldBallLoader />
            </Container>
        )
    }
    
    if (venueTag == undefined || venueStore.venue == undefined) {
        return (
            <Container style={{ marginTop: 24, marginBottom: 24 }}>
                <div style={{ fontSize: 26, textAlign: 'center', marginBottom: 16 }}>
                    Venue was not found
                </div>
            </Container>
        )
    }
    
    const submitHelp = () => {
        let hasError = false;

        if (helpRequest.firstName.length == 0) {
            hasError = true;
            setNameError(true);
        }

        if (helpRequest.phoneNumber.length < 10) {
            hasError = true;
            setMobileError(true);
        }

        if (helpRequest.helpKind.length == 0) {
            hasError = true;
            setHelpKindError(true);
        }
        
        if (hasError)
            return;
        
        ApiClient.createHelpRequest(
            venueStore.getVenueId!!,
            helpRequest.firstName,
            helpRequest.lastName,
            helpRequest.phoneNumber,
            helpRequest.emailAddress,
            helpRequest.helpKind
        )
            .then(response => {
                setSubmittedRequest(true);
            })
            .catch((error) => {
                
            });
    };
    
    const submittedView = () => {
        return <div style={{ padding: 32, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
            <CheckCircleFill size={44} color={Colors.GreenMatte} style={{ margin: 'auto' }} />;
            
            <div style={{ fontSize: 22, fontWeight: "bold" }}>
                Success!
            </div>

            <div style={{ fontSize: 18, marginTop: 8 }}>
                We're received your request... keep your mobile on hand.
            </div>
            
            <div style={{ fontSize: 18, marginTop: 8 }}>
                Someone will be in touch with you shortly.
            </div>
        </div>
    }

    const cancelledView = () => {
        return <div style={{ padding: 32, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
            <div style={{ fontSize: 22, fontWeight: "bold" }}>
                We've cancelled your Help Request
            </div>

            <div style={{ fontSize: 18, marginTop: 8 }}>
                If you ever need help or assistance, please check back here.
            </div>
        </div>
    }
    
    const normalView = () => {
        return <Fragment>
            <Form>
                <FormGroup tag="fieldset" style={{ margin: 0 }}>
                    <legend style={{ textAlign: 'center' }}>
                        How can we help you?
                    </legend>
                    
                    <FormGroup
                        check
                        style={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: 'center', marginBottom: 12, marginTop: 18 }}
                    >
                        <Input
                            style={{ width: 54 }}
                            name="radio1"
                            type="radio"
                            onClick={() => {
                                setHelpKindError(false);
                                setHelpRequest(prevState => ({
                                    ...prevState,
                                    helpKind: "Mental Health"
                                }))
                            }}
                        />
                        {' '}
                        <Label
                            style={{ marginLeft: 36 }}
                            check
                        >
                            Mental Health
                        </Label>
                    </FormGroup>
                    
                    <FormGroup
                        check
                        style={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: 'center', marginBottom: 12 }}
                    >
                        <Input
                            style={{ width: 54 }}
                            name="radio1"
                            type="radio"
                            onClick={() => {
                                setHelpKindError(false);
                                setHelpRequest(prevState => ({
                                    ...prevState,
                                    helpKind: "Problem Gambling Support"
                                }))
                            }}
                        />
                        {' '}
                        <Label
                            style={{ marginLeft: 36 }}
                            check
                        >
                            Problem Gambling Support
                        </Label>
                    </FormGroup>

                    <FormGroup
                        check
                        style={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: 'center', marginBottom: 12 }}
                    >
                        <Input
                            style={{ width: 54 }}
                            name="radio1"
                            type="radio"
                            onClick={() => {
                                setHelpKindError(false);
                                setHelpRequest(prevState => ({
                                    ...prevState,
                                    helpKind: "Stress"
                                }))
                            }}
                        />
                        {' '}
                        <Label
                            style={{ marginLeft: 36 }}
                            check
                        >
                            Stress
                        </Label>
                    </FormGroup>
                    
                    <FormGroup
                        check
                        style={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: 'center', marginBottom: 12 }}
                    >
                        <Input
                            style={{ width: 54 }}
                            name="radio1"
                            type="radio"
                            onClick={() => {
                                setHelpKindError(false);
                                setHelpRequest(prevState => ({
                                    ...prevState,
                                    helpKind: "Other"
                                }))
                            }}
                        />  
                        {' '}
                        <Label
                            style={{ marginLeft: 36 }}
                            check
                        >
                            Other
                        </Label>
                    </FormGroup>
                    
                    {helpKindError && (
                        <div style={{ color: 'red', fontSize: 16, marginTop: 0, marginBottom: 8, textAlign: 'center' }}>
                            Please select the kind of help your looking for
                        </div>
                    )}
                </FormGroup>
            </Form>
            
            <div style={{ marginTop: 20, marginBottom: 20, fontSize: 22, textAlign: 'center', fontWeight: 'bold' }}>
                LET'S TALK ABOUT IT - GET THE SUPPORT YOU NEED TODAY
            </div>
            
            <div className="flex-cen-stack">
                <CardText style={{ fontSize: '1.5rem', marginTop: 12 }}>
                    Contact Details
                </CardText>

                <input
                    css={css({ width: 360, padding: 6, marginBottom: 8, borderRadius: 8 })}
                    placeholder={'Enter First Name'}
                    type={'text'}
                    value={helpRequest.firstName}
                    onChange={({ target: { value } }) => {
                        setNameError(false);
                        setHelpRequest(prevState => ({
                            ...prevState,
                            firstName: value
                        }))
                    }}
                />

                {nameError && (
                    <div style={{ color: 'red', fontSize: 16, marginTop: -8, marginBottom: 8 }}>
                        Please enter your first name
                    </div>
                )}

                <input
                    css={css({ width: 360, padding: 6, marginBottom: 8, borderRadius: 8 })}
                    placeholder={'Enter Last Name'}
                    type={'text'}
                    value={helpRequest.lastName}
                    onChange={({ target: { value } }) => setHelpRequest(prevState => ({
                        ...prevState,
                        lastName: value
                    }))}
                />

                <input
                    css={css({ width: 360, padding: 6, marginBottom: 8, borderRadius: 8 })}
                    placeholder={'Enter Mobile Number'}
                    type={'text'}
                    value={helpRequest.phoneNumber}
                    onChange={({ target: { value } }) => {
                        setMobileError(false);
                        setHelpRequest(prevState => ({
                            ...prevState,
                            phoneNumber: value
                        }))
                    }}
                />
                
                {mobileError && (
                    <div style={{ color: 'red', fontSize: 16, marginTop: -8, marginBottom: 8 }}>
                        Invalid mobile number, please try again
                    </div>
                )}
            </div>
            
            <button
                className="btn btn-primary"
                type="submit"
                onClick={submitHelp}
                css={css({
                    marginTop: 12,
                    marginBottom: 6,
                    backgroundColor: '#353637',
                    color: '#EB462C',
                    fontSize: 16,
                    fontWeight: "bold"
                })}
            >
                Submit
            </button>

            <button
                className="btn btn-primary"
                type="submit"
                onClick={() => setCancelRequest(true)}
                css={css({
                    marginTop: 6,
                    marginBottom: 12,
                    backgroundColor: '#353637',
                    color: '#EB462C',
                    fontSize: 16,
                    fontWeight: "bold"
                })}
            >
                Cancel, I am Ok
            </button>
        </Fragment>
    };

    // https://stackoverflow.com/questions/46592833/how-to-use-switch-statement-inside-a-react-component
    // const ContentRenderer = ({ status }) => {
    //     const renderContent = React.useCallback(() => {
    //         switch (status) {
    //             case 'CONNECTING':
    //                 return <p className="connecting">Connecting...</p>;
    //
    //             case 'CONNECTED':
    //                 return <p className="success">Connected Successfully!</p>
    //
    //             default:
    //                 return null;
    //
    //         }
    //     }, [status]);

    return (
        <Container style={{ marginTop: 64, marginBottom: 24, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
            <img style={{ width: '100%', maxWidth: 800, margin: 'auto', marginBottom: 44 }}
                 src={"https://ok2p-test-bucket.s3.ap-southeast-2.amazonaws.com/ok2play_logo_dark.png"} />

            <div style={{ margin: 8, fontSize: 20, textAlign: 'center' }}>
                We are here to provide assistance to everyone who needs a little help.
            </div>
            
            <Card style={{ marginTop: 18 }}>
                <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                    {cancelRequest ? (
                        cancelledView()
                    ) : (
                        <Fragment>
                            {submittedRequest ? (
                                submittedView()
                            ) : (
                                normalView()
                            )}
                        </Fragment>
                    )}
                </CardBody>
            </Card>
        </Container>
    );
});
