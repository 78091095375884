import * as React from "react";

export const BallLoader = () => {
    return <div className="ball-pulse">
        <div></div>
        <div></div>
        <div></div>
    </div>
};

export const GoldBallLoader = () => {
    return <div className="ball-pulse flex-cen-horizontal">
        <div style={{backgroundColor: '#CDAC3D'}}></div>
        <div style={{backgroundColor: '#CDAC3D'}}></div>
        <div style={{backgroundColor: '#CDAC3D'}}></div>
    </div>
};