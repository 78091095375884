/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from "@emotion/styled";
import React from "react";

export const Columns = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
});

export const Rows = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
});

export const PageTitle = styled.div({
    fontSize: 24,
    textAlign: 'center'
});

export const secondsSinceToTimestamp = (secondsSince?: number) => {
    if (!secondsSince) return undefined;

    if (secondsSince < 61) {
        return `${secondsSince}s`
    } else {
        let hours = 0;
        let mins = Math.ceil(secondsSince) / 60;
        let secs = Math.ceil(secondsSince) % 60;

        if (mins >= 60) {
            hours = Math.ceil(mins / 60);
            mins = Math.ceil(mins % 60);

            return `${hours}h ${mins}m`;
        } else {
            return `${mins}m ${secs}s`;
        }
    }
};

export const PageFooter = (props: {stateLocation?: string}) => {
    if (props.stateLocation != "NSW" &&
        props.stateLocation != "QLD") {
        return null;    
    }
    
    return <div css={{ width: '100%', backgroundColor: footerBgColor(props.stateLocation), textAlign: 'center' }}>
        <img
            src={footerImage(props.stateLocation)}
            css={{
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: 120
            }}
        />
    </div>
}

const footerImage = (stateLocation?: string) => {
    if (stateLocation == "QLD") {
        return "qld_okcheck_footer.png";
    } else if (stateLocation == "NSW") {
        return "okcheck_footer.png";
    }
};

const footerBgColor = (stateLocation?: string) => {
    if (stateLocation == "QLD") {
        return '#040504';
    } else if (stateLocation == "NSW") {
        return '#233E7D';
    }
};
    