import 'bootstrap/dist/css/bootstrap.css';
import './app.global.scss'

import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
// import registerServiceWorker from './registerServiceWorker';
import UserState, {userState} from "./state/UserState";
import ApiClient from './ApiClient';
import AppState, {appState} from "./state/AppState";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;
const rootElement = document.getElementById('root');

export const UserStore = createContext<UserState>(userState);
export const AppStore = createContext<AppState>(appState);

export const apiBaseUrl =
    process.env.NODE_ENV === 'production'
        ? 'https://api-prod.ok2play-dev.com/api'
        // : 'https://api.ok2play-dev.com/api';
        // : 'https://api-prod.ok2play-dev.com/api';
        : 'http://192.168.0.77:5041/api';

ApiClient.init(apiBaseUrl, userState);

ReactDOM.render(
    <AppStore.Provider value={appState}>
        <UserStore.Provider value={userState}>
            <BrowserRouter basename={baseUrl}>
                <App />
            </BrowserRouter>
        </UserStore.Provider>
    </AppStore.Provider>
    ,
    rootElement
);

// registerServiceWorker();

